import * as Sentry from '@sentry/react'

import React, {
	useCallback,
	useEffect,
	useState
} from 'react'
import {
	checkLogin,
	selectLoggedIn,
	selectLoginChecked,
} from 'features/user/login/loginSlice'
import {
	getLocalStorageItem,
	setLocalStorageItem,
	usePrevious,
} from 'utils/utils'
import {
	getSearchConfig,
	getSearchConfigDefaults,
	getShopSettings,
	selectBatterySearchConfig,
	selectCarSearchConfig,
	selectIndustrySearchConfig,
	selectOilSearchConfig,
	selectSettings,
} from 'features/app/settings/settingsSlice'
import {
	getUserInfo,
	selectUserInfo,
} from 'features/user/userInfo/userInfoSlice'
import {
	selectHighResolution,
	selectOverlay,
	selectScreenWidth,
	setDocumentInfo,
	setHighResolution,
} from 'features/app/documentInfo/documentInfoSlice'
import {
	useDispatch,
	useSelector
} from 'react-redux'

import { AppNotificationsOverlay } from '../features/notifications/AppNotificationsOverlay/AppNotificationsOverlay'
import ArticleLinkWindow from 'features/articles/articleLink/ArticleLinkWindow/ArticleLinkWindow'
import ContactInformation from 'features/contactInformation/ContactInformation/ContactInformation'
import DueInvoicesWindow from 'features/user/dueInvoices/DueInvoicesWindow/DueInvoicesWindow'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import ForcePasswordChange from 'features/user/password/ForcePasswordChange/ForcePasswordChange'
import LayoutWrapper from 'utils/wrappers/LayoutWrapper'
import { LoadingOverlay } from '@barnsten-it/gummidata-react-components'
import LoginModal from 'features/user/login/LoginModal/LoginModal'
import MaintenancePage from 'pages/MaintenancePage/MaintenancePage'
import NewCustomerWindow from 'features/resellerApplication/ResellerApplicationWindow/ResellerApplicationWindow'
import NewVersionChecker from 'features/app/version/NewVersionChecker/NewVersionChecker'
import Routing from 'Router'
import UserLoginChecker from 'features/user/UserLoginChecker/UserLoginChecker'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import { fetchBanners } from 'features/banners/bannersSlice'
import { fetchCampaigns } from 'features/campaigns/campaignsSlice'
import { fetchNotifications } from 'features/user/notifications/notificationsSlice'
import { fetchSiteData } from 'features/siteData/siteDataSlice'
import { fetchVersion } from 'features/app/version/versionSlice'
import { fetchWarehouses } from 'features/warehouses/warehousesSlice'
import { getMainGroups } from 'features/mainGroups/mainGroupsSlice'
import grcPackage from '@barnsten-it/gummidata-react-components/package.json'
import i18nStartFunciton from '../i18n'
import i18next from 'i18next'
import { isIE } from 'react-device-detect'
import { isNullish } from 'utils/helpers'
import { selectFullScreen } from 'features/search/searchSettings/searchSettingsSlice'
import { useSettingsLoader } from 'utils/hooks/useSettings'
import { useUserRoles } from '../utils/hooks/useUserRoles'
import widthVariables from 'utils/enums/widthVariables'

/**
 * Main App script
 * @returns 
*/
function App() {
	const dispatch = useDispatch()
	
	// Selectors
	const loggedIn = useSelector(selectLoggedIn)
	const loginChecked = useSelector(selectLoginChecked)
	const settings = useSelector(selectSettings)
	const screenWidth = useSelector(selectScreenWidth)
	const userInfo = useSelector(selectUserInfo)
	const overlay = useSelector(selectOverlay)
	const fullScreen = useSelector(selectFullScreen)
	const highResolution = useSelector(selectHighResolution)

	// Check before render
	const industrySearchConfig = useSelector(selectIndustrySearchConfig)
	const carSearchConfig = useSelector(selectCarSearchConfig)
	const batterySearchConfig = useSelector(selectBatterySearchConfig)
	const oilSearchConfig = useSelector(selectOilSearchConfig)

	const {
		hasCustomerAdminRole,
		hasInvoicesRole,
	} = useUserRoles()

	// States
	const [firstRender, setFirstRender] = useState(true)
	const { settingsLoader } = useSettingsLoader()
	const [currentLanguage, setCurrentLanguage] = useState(null)

	//const [reconnecting, setReconnecting] = useState(false)
	// const connectionTimer = useRef(null)
	// const reconnectCount = useRef(0)

	/**
	 * Run initial logic on application mount
	 */
	const onAppMounted = async () => {
		// Get and set version
		if (process.env.NODE_ENV !== 'development') {
			dispatch(fetchVersion())
		}

		// Get site settings (need to be first)
		const result = await dispatch(getShopSettings())
		
		// Load all quick settings
		settingsLoader()

		// No payload returned for settings, stop execution
		if (!result.payload)
			return

		const startLanguage = result.payload.languageConfig.defaultLanguage
		i18nStartFunciton(startLanguage)

		// Sets the language settings
		if (getLocalStorageItem('languageCode')) {
			i18next.changeLanguage(getLocalStorageItem('languageCode'))
		} else if (startLanguage) {
			i18next.changeLanguage(startLanguage)
			setLocalStorageItem('languageCode', startLanguage)
		}
	}

	/**
	 * EventListener for documentInfo 
	 */
	const doResizeCheck = useCallback((e) => {
		const width = document.documentElement.clientWidth
		const height = document.documentElement.clientHeight
		
		// Set high resolution if screen is wider then 1920px
		if (width > 1920) {
			dispatch(setHighResolution(true))
		} else {
			dispatch(setHighResolution(false))
		}
		
		// Set screen sizes in redux
		dispatch(setDocumentInfo({
			width: width,
			height: height,
		}))
	}, [dispatch])

	const reloadNamespaces = (prefix) => {
		Object.keys(i18next.services.resourceStore.data).forEach((key) => {
			const resource = i18next.services.resourceStore.data[key]
			Object.keys(resource).forEach((property) => {
				if (property.startsWith(prefix)) {
					i18next.reloadResources(key, property)
				}
			})
		})
	}

	// const reconnect = useCallback(() => {
	// 	if (connectionTimer.current !== null)
	// 		return

	// 	setReconnecting(true)

	// 	const pause = reconnectCount.current * 10000
	// 	connectionTimer.current = setTimeout(async () => {
	// 		connectionTimer.current = null
	// 		reconnectCount.current += 1
	// 		onAppMounted()
	// 	}, pause)
	// }, [onAppMounted])

	useEffect(() => {
		if (firstRender) {
			window.addEventListener('resize', debounce(doResizeCheck, 500))
			doResizeCheck()
		}

		return () => {
			window.removeEventListener('resize', debounce(doResizeCheck, 500))
			setFirstRender(false)
		}
	}, [firstRender, doResizeCheck])

	
	/**
	 * Login
	 */
	useEffect(() => {
		if (settings) {
			dispatch(checkLogin())
			dispatch(getMainGroups())
		}
	}, [settings, loginChecked, dispatch])


	const prevLoggedIn = usePrevious(loggedIn)


	// Fetch data for logged in users
	useEffect(() => {
		if (loggedIn && !prevLoggedIn && settings) {
			dispatch(getUserInfo())
			dispatch(fetchNotifications())
			dispatch(fetchWarehouses())
		}
	}, [settings, loggedIn, prevLoggedIn, dispatch])


	/**
	 * Fetch banners
	 * 
	 * @effect when logged in and setting exists load the banners, 
	 * but reload on language change.
	 */
	useEffect(() => {
		if (loggedIn && settings && currentLanguage) {
			dispatch(fetchBanners(currentLanguage))
		}
	}, [loggedIn, settings, currentLanguage, dispatch])


	useEffect(() => {
		if (loggedIn)
			reloadNamespaces('webtext')
	}, [loggedIn])

	useEffect(() => {
		if (userInfo?.id) {
			// Add user to Sentry if there is an email
			Sentry.setUser({
				id: userInfo.id,
				email: userInfo.emailAddress,
			})
		} else {
			// Remove user to Sentry if there is no email
			Sentry.configureScope(scope => scope.setUser(null))
		}
	}, [userInfo])

	// Fetch search config on user log in and change
	useEffect(() => {
		if (isNullish(userInfo?.id)) return
		dispatch(getSearchConfig())
		dispatch(getSearchConfigDefaults())
		dispatch(fetchSiteData())
		// Grab the campaigns avaiable for the user
		dispatch(fetchCampaigns())
	}, [dispatch, userInfo])

	// useEffect(() => {
	// 	if (status === 'failed' && prevStatus !== 'failed')
	// 		reconnect()
	// }, [status, prevStatus, reconnect])


	/**
	 * Set language when it changes
	 */
	useEffect(() => {
		const handleLanguageChange = () => {
			setCurrentLanguage(i18next.language)
		}
		i18next.on('languageChanged', handleLanguageChange)
		return () => {
			i18next.off('languageChanged', handleLanguageChange)
		}
	}, [])

	// Dispatch on mount
	useEffect(() => {
		onAppMounted()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	/**
		* Check browser
		*/
	if (isIE) {
		return (
			<div style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				height: '100vh',
				width: '100%'
			}}>
				<h1>{"We don't support this web browser."}</h1>
				<h3>Try <a href="https://browser-update.org/update-browser.html">updating</a> your browser, or upgrade to a modern browser like <a href="https://chromeenterprise.google/browser/download/">Chrome</a>.</h3>
			</div>
		)
	}


	/**
	 * Wait for settings, translations and searchConfigs to load
	 */
	if (
		// Shop settings are loaded
		!settings ||

		// We have checked if we are logged in
		!loginChecked ||

		// Check if logged in and user info is loaded
		(loggedIn && !userInfo) || 

		// Check if translations exists before we show the app
		typeof i18next.exists('common.all') === 'undefined' ||
		
		// Check if inlogged and search configs is loaded
		(loggedIn && typeof industrySearchConfig === 'undefined') ||
		(loggedIn && typeof carSearchConfig === 'undefined') ||
		(loggedIn && typeof batterySearchConfig === 'undefined') ||
		(loggedIn && typeof oilSearchConfig === 'undefined') 
	) {
		return (
			<LoadingOverlay />
		)
	}


	/**
		* Set title 
		*/
	document.title = settings.title


	/**
		* maintenance mode
		*/
	if (settings.maintenance) {
		return (
			<MaintenancePage />
		)
	}

	return (
		<ErrorBoundary>
			<div 
				className={
					classNames('App', 
						settings.theme, 
						{ 
							'mobile-view': (screenWidth < widthVariables['md-max']), 
							'full-screen': !highResolution || fullScreen
						}
					)} 
				data-version={process.env.REACT_APP_VERSION}
				data-grc-version={grcPackage.version}
			>
				<span className={classNames('main-overlay', { overlay })} />

				<LayoutWrapper>
					<Routing />
				</LayoutWrapper>

				<NewVersionChecker time={5} />
				<UserLoginChecker />

				<LoginModal />
				<ContactInformation />
				<ForcePasswordChange />
				<NewCustomerWindow />
				<AppNotificationsOverlay />
				{
					(settings?.pageConfig?.invoicePage && (hasCustomerAdminRole || hasInvoicesRole)) &&
					<DueInvoicesWindow />
				}
				<ArticleLinkWindow />
			</div>
		</ErrorBoundary>
	)
}

export default App
